import React, { FC } from 'react';
import Switch from 'react-switch';

interface Prop {
  onToggle: () => void;
  isOn: boolean;
}

const ToggleSwitch: FC<Prop> = ({ isOn = true, onToggle }) => {
  const MoonSwitch = () => {
    return (
      <svg
        className="svg"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        style={{ position: 'absolute', top: '0px' }}
        viewBox="1 -5 14 26">
        <path d="M14.9508 9.30369C14.9005 9.26885 14.8408 9.25018 14.7796 9.25018C14.7184 9.25018 14.6587 9.26885 14.6084 9.30369C13.3756 10.0583 11.9243 10.3761 10.4885 10.2058C9.05276 10.0355 7.71627 9.38713 6.6946 8.36516C5.67293 7.34319 5.02564 6.00724 4.85714 4.57277C4.68863 3.13831 5.00873 1.68896 5.76581 0.458486C5.81046 0.407426 5.83648 0.342765 5.83963 0.275041C5.84279 0.207317 5.82289 0.140522 5.78317 0.085542C5.74346 0.0305618 5.68628 -0.00936268 5.62095 -0.0277254C5.55562 -0.0460882 5.48598 -0.0418066 5.4234 -0.0155785C4.1639 0.377981 3.02733 1.08946 2.12356 2.0501C1.2198 3.01073 0.5794 4.18802 0.264274 5.46816C-0.0508517 6.74831 -0.0300479 8.08802 0.324675 9.35779C0.679397 10.6276 1.35604 11.7844 2.2892 12.7166C3.22236 13.6488 4.38047 14.3247 5.65159 14.679C6.9227 15.0334 8.26384 15.0542 9.54535 14.7394C10.8269 14.4246 12.0054 13.7849 12.967 12.8821C13.9287 11.9793 14.6409 10.8439 15.0349 9.58573C15.0463 9.53546 15.0446 9.48311 15.0299 9.43371C15.0151 9.38431 14.9879 9.33953 14.9508 9.30369Z" fill="#F5F5F7" fill-rule="evenodd"/>
      </svg>
    );
  };

  const SunSwitch = () => {
    return (
      <svg 
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        style={{ position: 'absolute', top: '0px' }}
        viewBox="0 -3 14 20" 
      >
        <g clip-path="url(#clip0_305_20895)">
        <path d="M7.96875 11.25C10.2987 11.25 12.1875 9.3612 12.1875 7.03125C12.1875 4.7013 10.2987 2.8125 7.96875 2.8125C5.6388 2.8125 3.75 4.7013 3.75 7.03125C3.75 9.3612 5.6388 11.25 7.96875 11.25Z" fill="#F5F5F7"/>
        <path d="M8.4375 0H7.5V1.875H8.4375V0Z" fill="#F5F5F7"/>
        <path d="M8.4375 12.1875H7.5V14.0625H8.4375V12.1875Z" fill="#F5F5F7"/>
        <path d="M15 6.5625H13.125V7.5H15V6.5625Z" fill="#F5F5F7"/>
        <path d="M2.8125 6.5625H0.9375V7.5H2.8125V6.5625Z" fill="#F5F5F7"/>
        <path d="M11.9459 10.3458L11.283 11.0087L12.6088 12.3345L13.2717 11.6716L11.9459 10.3458Z" fill="#F5F5F7"/>
        <path d="M3.32856 1.72796L2.66565 2.39087L3.99146 3.71668L4.65437 3.05378L3.32856 1.72796Z" fill="#F5F5F7"/>
        <path d="M3.99122 10.3455L2.66541 11.6713L3.32831 12.3342L4.65412 11.0084L3.99122 10.3455Z" fill="#F5F5F7"/>
        <path d="M12.6089 1.72808L11.2831 3.05389L11.946 3.7168L13.2718 2.39099L12.6089 1.72808Z" fill="#F5F5F7"/>
        </g>
      <defs>
      <clipPath id="clip0_305_20895">
      <rect width="15" height="15" fill="#F5F5F7"/>
      </clipPath>
      </defs>
      </svg>
      );
    };
  
  return (
    <>
      <div style={{ display: 'flex' }}>
        <Switch
          className={'react-switch'}
          onChange={onToggle}
          checked={isOn}
          offColor={'#162241'}
          offHandleColor={'#0f172a'}
          onColor={'#A37F0C'}
          onHandleColor={'#FBBF00'}
          handleDiameter={30}
          uncheckedIcon={<MoonSwitch />}
          checkedIcon={<SunSwitch />}
          boxShadow={'0px 1px 5px rgba(0, 0, 0, 0.6)'}
          activeBoxShadow={'0px 0px 1px 10px rgba(0, 0, 0, 0.2)'}
          height={20}
          width={48}
          id={'material-switch'}
        />
      </div>
    </>
  );
};

export default ToggleSwitch;

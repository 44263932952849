import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ToggleSwitch from './ToggleSwitch';
import { GlobalStyles, lightTheme, darkTheme } from '../../themes';
import { Fade as Hamburger } from 'hamburger-react';

const NavBarContainer = styled.nav`
  width: 100vw;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  z-index: 2;

  background-color: ${props => props.theme.backgroundColor};
  height: ${(props) => (props.showMenu ? '100vh' : '80px')};
  max-height: ${(props) => (props.showMenu ? '-webkit-fill-available' : '80px')};
  @media (min-width: 1085px) {
    height: 80px;
  }
`;

const NavBarInnerContainer = styled.div`
  width: 100vw;
  display: flex;
  height: 80px;
`;

const NavBarLeftContainer = styled.div`
  flex: 30vh;
  display: flex;
  justify-content: flex-start;
  padding-left: 1%;
`;

const NavBarWordmarkContainer = styled.div`
  align-self: center;
`;

const Logo = styled.img`
  margin: 10px;
  min-width 60px;
  height: auto;
`;

Logo.defaultProps = {
  src: 'https://file.rendit.io/n/qvay2ltFvZpLP14u5Hyv.png',
};

const LogoWordmarkLight = styled.span`
  font-family: 'Baloo 2';
  font-size: 28px;
  font-weight: 700;
  line-height: 155%;
  color: ${props => props.theme.wordmarkLightColor};
`;

const LogoWordmarkDark = styled.span`
  font-family: 'Baloo 2';
  font-size: 28px;
  font-weight: 700;
  line-height: 155%;
  color: ${props => props.theme.wordmarkHouseColor};
`;


const LogoWordmarkVersion = styled.span`
  font-family: 'Baloo 2';
  font-size: 18px;
  font-weight: 700;
  vertical-align: top;
  color: ${props => props.theme.wordmarkLightColor};
`;

const NavBarRightContainer = styled.div`
  flex: 70vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2%;
`;

const NavBarOptionsContainer = styled.ul`
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 1085px) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  font-family: Cantarell, sans-serif;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 2%;
  text-decoration: none;
  padding-right: 20px;

  color: ${props => props.theme.navOptionTextColor};

  &:hover,
  &.active {
    color: ${props => props.theme.navOptionHoverTextColor};
  }
`;

const NavBarWalletButtonContainer = styled.div`
  @media (max-width: 1085px) {
    display: none;
  }
`;

const NavBarWalletButtonMenuContainer = styled.div`
`;

const NavBarWalletConnectButton = styled.button`
  border-radius: 50px;
  width: 242px;
  height: 44px;
  padding: 0;
  border: none;
  cursor: pointer;

  box-shadow: ${props => props.theme.boxShadow};
  background: ${props => props.theme.walletConnectColor};
`;

const NavBarWalletConnectInfoContainer = styled.div`
  display: flex;
  line-height: 44px;
  justify-content: space-evenly;
`;

const NavBarWalletConnectInfoText = styled.span`
  max-width: 155px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Cantarell, sans-serif;
  font-weight: 700;
  letter-spacing: 2%;
  font-size: 14px;

  color: ${props => props.theme.walletConnectTextColor};
`;

const WalletIcon = styled.img`
`;

WalletIcon.defaultProps = {
  src: 'https://file.rendit.io/n/02MetHEsArN9UNu2XWDS.svg',
};

const NavBarExtendedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50vh;
  justify-content: space-between;
  overflow: hidden;
  padding-top: 100px;
  
  @media (min-width: 1085px) {
    display: none;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-right: 20px;

  @media (max-width: 1085px) {
    display: none;
  }
`;

const ToggleMenuContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-right: 20px;
`;

const HamburgerContainer = styled.div`
  @media (min-width: 1085px) {
    display: none;

    background-color: ${props => props.theme.backgroundColor};
  }
`;

interface Prop {
  isSignedIn: boolean;
  onClickConnect: React.MouseEventHandler<HTMLButtonElement>;
  onClickDisconnect: React.MouseEventHandler<HTMLButtonElement>;
  accountId: string;
  theme: string;
  toggleTheme: () => void;
}

const NavBar: FC<Prop> = ({ isSignedIn, onClickConnect, onClickDisconnect, accountId, toggleTheme, theme }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [href, setHref] = useState('/');

  const shouldShowMenu = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu]);

  return (
    <>
    <NavBarContainer showMenu={showMenu}>
      <NavBarInnerContainer>
        <NavBarLeftContainer>
          <Logo/>
          <NavBarWordmarkContainer>
            <p>
              <LogoWordmarkLight id={theme}>LIGHT</LogoWordmarkLight>
              <LogoWordmarkDark id={theme}>HOUSE</LogoWordmarkDark>
              <LogoWordmarkVersion id={theme}><sup>v.0.9</sup></LogoWordmarkVersion>
            </p>
          </NavBarWordmarkContainer>
        </NavBarLeftContainer>

        <NavBarRightContainer>
          <NavBarOptionsContainer>
            <StyledLink to={"/"}>HOME</StyledLink>
            <StyledLink to={"/manual"}>MANUAL</StyledLink>
            <StyledLink to={"/automation"}>AUTOMATION</StyledLink>
            <StyledLink to={"/analytics"}>ANALYTICS</StyledLink>
          </NavBarOptionsContainer>
          <ToggleContainer>
            <ToggleSwitch onToggle={toggleTheme} isOn={theme === 'Dark' ? true : false} />
          </ToggleContainer>
          <NavBarWalletButtonContainer>
            {!isSignedIn && (
              <NavBarWalletConnectButton onClick={onClickConnect}>
                <NavBarWalletConnectInfoContainer>
                  <NavBarWalletConnectInfoText>CONNECT WALLET</NavBarWalletConnectInfoText>
                  <WalletIcon />
                </NavBarWalletConnectInfoContainer>
              </NavBarWalletConnectButton>
            )}
          {isSignedIn && (
            <NavBarWalletConnectButton onClick={onClickDisconnect}>
              <NavBarWalletConnectInfoContainer>
                <div>
                  <NavBarWalletConnectInfoText>{accountId.length > 16 ? accountId.substring(0, Math.min(8, accountId.length/2)).toUpperCase() + '...' : accountId.toUpperCase()}</NavBarWalletConnectInfoText>
                  {accountId.length > 16 && <NavBarWalletConnectInfoText>{accountId.substring(accountId.length - 8).toUpperCase()}</NavBarWalletConnectInfoText>}
                </div>
                <WalletIcon />
              </NavBarWalletConnectInfoContainer>
            </NavBarWalletConnectButton>
          )}
          </NavBarWalletButtonContainer>
          <HamburgerContainer>
            <Hamburger 
              rounded
              color={theme === 'Light' ? '#162241' : '#E0DAED'}
              onToggle={shouldShowMenu}
              toggled={showMenu}
            />
          </HamburgerContainer>
        </NavBarRightContainer>
      </NavBarInnerContainer>
      {showMenu && <NavBarExtendedContainer>
        <StyledLink onClick={shouldShowMenu} to={"/"}>HOME</StyledLink>
        <StyledLink onClick={shouldShowMenu} to={"/manual"}>MANUAL</StyledLink>
        <StyledLink onClick={shouldShowMenu} to={"/automation"}>AUTOMATION</StyledLink>
        <StyledLink onClick={shouldShowMenu} to={"/analytics"}>ANALYTICS</StyledLink>
        <NavBarWalletButtonMenuContainer>
          {!isSignedIn && (
              <NavBarWalletConnectButton onClick={onClickConnect}>
                <NavBarWalletConnectInfoContainer>
                  <NavBarWalletConnectInfoText>CONNECT WALLET</NavBarWalletConnectInfoText>
                  <WalletIcon />
                </NavBarWalletConnectInfoContainer>
              </NavBarWalletConnectButton>
            )}
            {isSignedIn && (
              <NavBarWalletConnectButton onClick={onClickDisconnect}>
                <NavBarWalletConnectInfoContainer>
                  <NavBarWalletConnectInfoText>{accountId.length > 14 ? accountId.substring(0, Math.min(8, accountId.length/2)).toUpperCase() : accountId.toUpperCase()}</NavBarWalletConnectInfoText>
                  {/* {accountId.length > 14 && <NavBarWalletConnectInfoText>{accountId.substring(-accountId.length/2).toUpperCase()}</NavBarWalletConnectInfoText>} */}
                  <WalletIcon />
                </NavBarWalletConnectInfoContainer>
              </NavBarWalletConnectButton>
            )}
        </NavBarWalletButtonMenuContainer>
          <ToggleMenuContainer showMenu={showMenu}>
            <ToggleSwitch onToggle={toggleTheme} isOn={theme === 'Dark' ? true : false} />
          </ToggleMenuContainer>
      </NavBarExtendedContainer>}
    </NavBarContainer>
    </>
  );
};

export default NavBar;

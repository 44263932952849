import React, { FC, useEffect } from 'react';
import {
	widget,
	ChartingLibraryWidgetOptions,
} from '../../../charting_library';
import styled from 'styled-components';
import Datafeed from './datafeed';
import { getLanguageFromURL } from '../../../utils';
import datafeed from './datafeed';
import analyticsDatafeed from './analyticsDatafeed';
interface Prop {
  theme: string,
	symbol: ChartingLibraryWidgetOptions['symbol'];
	interval: ChartingLibraryWidgetOptions['interval'];

	// BEWARE: no trailing slash is expected in feed URL
	datafeedUrl: string;
  timeframe: string;
  format: string;
	libraryPath: ChartingLibraryWidgetOptions['library_path'];
	chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'];
	chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'];
	clientId: ChartingLibraryWidgetOptions['client_id'];
	userId: ChartingLibraryWidgetOptions['user_id'];
	fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
	autosize: ChartingLibraryWidgetOptions['autosize'];
	studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
	container: ChartingLibraryWidgetOptions['container'];
  hideSideToolbar: boolean;
  showLineGraph: boolean;
  isAnalyticsPage: boolean;
}

const TradingChartContainer = styled.div`
  height: 400px;
  border-radius: 25px;
  position: relative;
  min-width: 250px;

  box-shadow: ${props => props.theme.boxShadow};
  background-color: ${props => props.theme.infoBoxColor};

  @media (min-width: 768px) {
    min-width: 600px;
  }
`;

const TradingChartComponent = styled.div`
  position: absolute;
  top: 8%;
  height: 85%;
  width: 100%;
`;

const TradingChart: FC<Prop> = React.forwardRef((props) => {
  const {
    symbol,
    interval,
    libraryPath,
    chartsStorageUrl,
    chartsStorageApiVersion,
    clientId,
    userId,
    fullscreen,
    autosize,
    studiesOverrides,
    theme,
    hideSideToolbar,
    timeframe,
    format="price",
    isAnalyticsPage=false,
    showLineGraph=false,
  } = props;

  let chartContainerRef = React.createRef();

  useEffect(() => {
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: symbol as string,
			// BEWARE: no trailing slash is expected in feed URL
			// tslint:disable-next-line:no-any
			datafeed: isAnalyticsPage ? analyticsDatafeed : datafeed,
			interval: interval as ChartingLibraryWidgetOptions['interval'],
			container: chartContainerRef.current,
			library_path: libraryPath as string,

			locale: getLanguageFromURL() || 'en',
			disabled_features: ['use_localstorage_for_settings'],
			enabled_features: ['study_templates', 'seconds_resolution', 'tick_resolution'],
			charts_storage_url: chartsStorageUrl,
			charts_storage_api_version: chartsStorageApiVersion,
			client_id: clientId,
			user_id: userId,
			fullscreen: fullscreen,
			autosize: autosize,
			studies_overrides: studiesOverrides,
      format: format,
      theme: theme,
      custom_css_url: 'css/style.css',
      custom_font_family: "Cantarell",
      hide_side_toolbar: hideSideToolbar,
      debug: false,
      timeframe: timeframe,
      time_frames: [
        { text: "100Y", resolution: "W", description: "All", title: "All" },
        { text: "5Y", resolution: "W", description: "5 Years" },
        { text: "1Y", resolution: "W", description: "1 Year" },
        { text: "3M", resolution: "60", description: "3 Month" },
        { text: "1M", resolution: "60", description: "1 Month" },
        { text: "5D", resolution: "5", description: "5 Days" },
        { text: "1D", resolution: "1", description: "1 Day" },
      ]
    };
  
    let tvWidget = new widget(widgetOptions);

    let bgColor = theme == 'Dark' ? '#162241' : '#FFFFFF' 
    tvWidget.applyOverrides({ 
      'paneProperties.background': bgColor,
      'paneProperties.backgroundType' : 'solid',
      ...(showLineGraph && { 
        "mainSeriesProperties.style": 3,
        "mainSeriesProperties.areaStyle.linecolor": "#FBBF00",
        "mainSeriesProperties.areaStyle.linewidth": 1,
        "paneProperties.legendProperties.showStudyTitles": false,
        "paneProperties.legendProperties.showStudyArguments": false,
      }),
    })

    tvWidget.onChartReady(() => {
      if (isAnalyticsPage) {
        // tvWidget.activeChart().createStudy('Compare', false, false, ['open', 'Deposited']);
        tvWidget.activeChart().createStudy('Overlay', true, false, { symbol: 'Deposited' });

        // const priceScale = tvWidget.activeChart().getPanes()[0].getRightPriceScales()[0]; 
        // priceScale.setMode(0);
      }
      
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute("title", "Click to show a notification popup");
        button.classList.add("apply-common-tooltip");
        button.addEventListener("click", () =>
          tvWidget.showNoticeDialog({
            title: "Notification",
            body: "TradingView Charting Library API works correctly",
            callback: () => {
              console.log("Noticed!");
            },
          })
        );

        button.innerHTML = "Check API";
      });
    });

    return () => {
      // if (tvWidget !== null) {
      //   tvWidget.remove();
      //   tvWidget = null;
      // }
    };
  }, [theme]);

  return (
    <>
      <TradingChartContainer>
        <TradingChartComponent className="TVChartContainer" id="tv_chart_container" ref={chartContainerRef} />
      </TradingChartContainer>
    </>
  );
});

export default TradingChart;
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import TableRow from './TableRow';
import TableHeadItem from './TableHeadItem';
import { HistLiquidationsTable, TopLiquidatingTable, HighestRiskLoansTable } from '../types/Types';

interface Prop {
  tableOptions: HistLiquidationsTable | TopLiquidatingTable | HighestRiskLoansTable;
  theadData: string[];
  selected: string;
  numRows: number;
  numColumns: number;
  selectedAction?: Function;
  onChangeFilter?: (e: React.MouseEvent<HTMLElement>) => void;
}

const TableViewContainer = styled.div`
  max-height: ${props => (props.numRows * 52.5) + 'px'};
  overflow-y: scroll;
  overflow-x: scroll;
  border-radius: 15px;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  box-shadow: ${props => props.theme.boxShadow};
`;

const TableViewComponent = styled.table`
  border-collapse: collapse;
  min-width: 100%;
  width: 100%;
  word-wrap:break-word;
  background: ${props => props.theme.infoBoxColor};

  @media (min-width: 768px) {
    table-layout: fixed;
  }

  @media (max-width: 768px) {
    /* Conditionally apply styles based on a prop */
    ${props => props.numColumns && props.numColumns <= 3 ? 'table-layout: fixed;' : ''};
  }

  && tbody > tr:hover > td {
    background-color: ${props => props.theme.tableHoverColor};
    color: ${props => props.theme.tableHoverTextColor} !important;
    border: none;
  }
  && thead {
    background: ${props => props.theme.infoBoxColor};
    position: sticky;
    top: 0;
  }
`;

const TableViewTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TableViewText = styled.div`
  font-family: Cantarell, sans-serif;
  font-weight: 700;
  font-size: 13.5px;
  letter-spacing: 2%;
  margin-right: 2%;
  padding-bottom: 0.5em;
  cursor: pointer;
  @media (min-width: 1250px) {
    font-size: 16px;
  }
  color: ${props => props.selected ? props.theme.selectedRowText : props.theme.unselectedRowText }; 
`;


const TableView: FC<Prop> = ({ tableOptions = {}, selected, selectedAction, numRows,  onChangeFilter, theadData }) => {      
  const [currentSort, setCurrentSort] = useState('default');
  const [currentIndex, setCurrentIndex] = useState(0);

  const sortTypes = {
    up: {
      class: 'sort-up',
      fn: (a, b) => compareNumbers(a[currentIndex].sortValue,b[currentIndex].sortValue)
    },
    down: {
      class: 'sort-down',
      fn: (a, b) => compareNumbers(b[currentIndex].sortValue, a[currentIndex].sortValue)
    },
    default: {
      class: 'sort',
      fn: (a, b) => a
    }
  };

  function compareNumbers(a, b) {
    if (typeof a === 'number' && typeof b === 'number') {
      return a - b;
    }
    // check for num vs string
    if (typeof a === 'number' && typeof b === 'string') {
      return -1;
    }
    // check for string vs num
    if (typeof a === 'string' && typeof b === 'number') {
      return 1;
    }
    // check for string vs string
    if (typeof a === 'string' && typeof b === 'string') {
      if (a < b) return -1;
      else return 1;
    }
    return 0;
  }

  const onSortChange = (index) => {
    let nextSort: string;

    if (currentSort === 'down') nextSort = 'up';
    else if (currentSort === 'up') nextSort = 'default';
    else if (currentSort === 'default') nextSort = 'down';

    setCurrentSort( nextSort );
    setCurrentIndex( index );
  };

  return (
    <div>
      <TableViewTextContainer style={{ maxHeight: '250px' }}>
        {Object.keys(tableOptions).map((title) => {
          if (title.toLowerCase() === selected.toLowerCase()) {
            return <TableViewText selected={true}>{title.toUpperCase()}</TableViewText>;
          } else {
            return <TableViewText onClick={onChangeFilter} selected={false}>{title.toUpperCase()}</TableViewText>;
          }
        })}
      </TableViewTextContainer>
      <TableViewContainer numRows={numRows}>
        <TableViewComponent numColumns={theadData.length}>
          <thead>
            <tr>
              {theadData.map((h, i) => {
                return (
                  <TableHeadItem
                    key={i}
                    item={h}
                    onClick={() => onSortChange(i)}
                    currentSort={sortTypes[currentSort]}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableOptions[selected].sort(sortTypes[currentSort].fn).map((item, i) => {              
              return <TableRow key={i} data={item} onClick={selectedAction} />;
            })}
          </tbody>
        </TableViewComponent>
      </TableViewContainer>
    </div>
  );
};

export default TableView;

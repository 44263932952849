import { createGlobalStyle } from 'styled-components';

export const lightTheme = {
  backgroundColor: '#F5F5F7',
  color: '#0f172a',
  boxShadow: '2px 4px 12px rgb(0 0 0 / 8%)',
  userInfoColor: '#162241',
  userInfoDataTextColor: '#E0DAED',
  userInfoTextColor: '#fbbf00',
  wordmarkLightColor: '#0F172A',
  wordmarkHouseColor: '#fbbf00',
  navOptionTextColor: '#0F172A',
  navOptionHoverTextColor: '#FBBF00',
  walletConnectColor: '#FFFFFF',
  walletConnectTextColor: '#0F172A',
  infoBoxColor: '#FFFFFF',
  infoColor: '#fbbf00',
  infoDataColor: '#0F172A',
  selectedRowText: '#767676',
  unselectedRowText: '#0F172A',
  headerTextColor: '#0f172a',
  tableRowTextColor: '#767676',
  tableHoverColor: '#162241',
  tableHoverTextColor: '#F5F5F7',
};

export const darkTheme = {
  backgroundColor: '#0f172a',
  color: '#f5f5f7',
  boxShadow: '2px 4px 12px rgb(0 0 0 / 8%)',
  userInfoColor: '#FBBF00',
  userInfoDataTextColor: '#0F172A',
  userInfoTextColor: '#9D7C11',
  wordmarkLightColor: '#e0daed',
  wordmarkHouseColor: '#fbbf00',
  navOptionTextColor: '#e0daed',
  navOptionHoverTextColor: '#FBBF00',
  walletConnectColor: '#162241',
  walletConnectTextColor: '#e0d9ed',
  infoBoxColor: '#162241',
  infoColor: '#E0DAED',
  infoDataColor: '#FBBF00',
  selectedRowText: '#E0DAED',
  unselectedRowText: '#50545c',
  headerTextColor: '#FBBF00',
  tableRowTextColor: '#E0DAED',
  tableHoverColor: '#fbbf00',
  tableHoverTextColor: '#0F172A',
};

export const GlobalStyles = createGlobalStyle`
  html {
    background-color: ${(props) => props.theme.backgroundColor};
  }
  body {
    margin: 0;
    padding: 0;
  }
`;

import {
	makeGraphQLRequest,
} from '../../../utils';
import getConfig from '../../constants/config';

const channelToSubscription = new Map();
const lastBarsCache = new Map();
const configurationData = {
	supported_resolutions: ['1D', '1W', '1M', '6M', '12M'],
};
const CONTRACT_ID = 'contract.main.burrow.near';

// const ENDPOINT = "http://0.0.0.0:8080/graphql/";
const ENDPOINT = "https://lighthouse-sle-api-developer.azure-api.net/graphql/";

const config = getConfig('production');

const VARIABLES = {
	networkId: config.networkId,
	nodeUrl: config.nodeUrl,
	walletUrl: config.walletUrl,
	helperUrl: config.helperUrl,
	explorerUrl: config.explorerUrl,
	limit: 10,
	accountId: CONTRACT_ID
};

const HISTORY_QUERY = `
	{
		getTVLData {
			timestamp,
			depositedValue,
			borrowedValue
		}
	}
`;

const symbols = [{
	symbol: "Borrowed",
	full_name: "Borrowed",
	description: "",
	exchange: "Burrow",
	type: "crypto",
	ticker: "Borrowed"
},
{
	symbol: "Deposited",
	full_name: "Deposited",
	description: "",
	exchange: "Burrow",
	type: "crypto",
	ticker: "Deposited"
}];

export default {
    onReady: (callback) => {
		console.log('[onReady]: Method call');
		setTimeout(() => callback(configurationData));
    },
    searchSymbols: async (
			userInput,
			exchange,
			symbolType,
			onResultReadyCallback,
		) => {
		console.log('[searchSymbols]: Method call');
		onResultReadyCallback(symbols);
    },
    resolveSymbol: async (
			symbolName,
			onSymbolResolvedCallback,
			onResolveErrorCallback,
			extension
		) => {
			try {
				console.log('[resolveSymbol]: Method call', symbolName);
				
				const symbolItem = symbols.find(({
					full_name,
				}) => full_name === symbolName);
				if (!symbolItem) {
					console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
					// onResolveErrorCallback('cannot resolve symbol');
					throw Error;
				}
	
			var symbolInfo = {
				ticker: symbolItem.full_name,
				name: symbolItem.symbol,
				description: symbolItem.description,
				type: symbolItem.type,
				session: '24x7',
				timezone: 'Etc/UTC',
				exchange: symbolItem.exchange,
				minmov: 1,
				pricescale: .01,
				// intraday_multipliers: ['1'],
				// seconds_multipliers: ['1'],
				// daily_multipliers: ['1','7'],
				// has_intraday: true,
				// has_seconds: true,
				// has_no_volume: true,
				has_daily: true,
				// weekly_multipliers: ['1'],
				// monthly_multipliers: ['1', '3', '12'],
				has_weekly_and_monthly: true,
				supported_resolutions: configurationData.supported_resolutions,
				volume_precision: 2,
				data_status: 'streaming',
			};
	
			console.log('[resolveSymbol]: Symbol resolved', symbolName);
			onSymbolResolvedCallback(symbolInfo);
			} catch(err) {
				onResolveErrorCallback('cannot resolve symbol');
			}
    },
    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
			const { from, to, firstDataRequest } = periodParams;
			console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
			try {
				const data = await makeGraphQLRequest(ENDPOINT, HISTORY_QUERY, VARIABLES);
				if (!data || data.length === 0 || !data.data) {
					// "noData" should be set if there is no data in the requested period.
					onHistoryCallback([], {
						noData: true,
					});
					return;
				}

				let bars = [];
				data.data.getTVLData.map(d => {	
					let time = new Date(Number(d.timestamp)).valueOf();
					if (time / 1000 >= from && time / 1000 < to) {
						if (symbolInfo.name === 'Borrowed') { 
							bars = [...bars, {
								time: time,
								open: d.borrowedValue,
								high: d.borrowedValue,
								low: d.borrowedValue,
								close: d.borrowedValue,
							}];
						}
						
						if (symbolInfo.name === 'Deposited') { 
							bars = [...bars, {
								time: time,
								open: d.depositedValue,
								high: d.depositedValue,
								low: d.depositedValue,
								close: d.depositedValue,
							}];
						}
					}
				});
				
				if (firstDataRequest) {
					lastBarsCache.set(symbolInfo.name, {
						...bars[bars.length - 1],
					});
				}

				console.log(`[getBars]: returned ${bars.length} bar(s) for bars`);
				onHistoryCallback(bars, {
					noData: false,
				});
			} catch (error) {
				console.log('[getBars]: Get error', error);
				onErrorCallback(error);
			}
		},
    subscribeBars: (
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscriberUID,
			onResetCacheNeededCallback,
		) => {
        console.log('[subscribeBars]: Method call with subscribeUID:', subscriberUID);
		subscribeOnStream(
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscriberUID,
			onResetCacheNeededCallback,
			lastBarsCache.get(symbolInfo.full_name),
		);
    },
    unsubscribeBars: (subscriberUID) => {
        console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
		unsubscribeFromStream(subscriberUID);
    },
};

function subscribeOnStream(
	symbolInfo,
	resolution,
	onRealtimeCallback,
	subscriberUID,
	onResetCacheNeededCallback,
	lastDailyBar,
) {
	const parsedSymbol = symbolInfo.full_name;
	const channelString = `0~${parsedSymbol.exchange}`;
		// const channelString = `0~${parsedSymbol.exchange}~${parsedSymbol.fromSymbol}~${parsedSymbol.toSymbol}`;
	const handler = {
		id: subscriberUID,
		callback: onRealtimeCallback,
	};
	let subscriptionItem = channelToSubscription.get(symbolInfo.full_name);
	if (subscriptionItem) {
		// already subscribed to the channel, use the existing subscription
		subscriptionItem.handlers.push(handler);
		return;
	}
	subscriptionItem = {
		subscriberUID,
		resolution,
		lastDailyBar,
		handlers: [handler],
	};
	channelToSubscription.set(channelString, subscriptionItem);
	console.log('[subscribeBars]: Subscribe to streaming. Channel:', channelString);
	socket.emit('SubAdd', { subs: [channelString] });
}

function unsubscribeFromStream(subscriberUID) {
	// find a subscription with id === subscriberUID
	for (const channelString of channelToSubscription.keys()) {
		const subscriptionItem = channelToSubscription.get(channelString);
		const handlerIndex = subscriptionItem.handlers
			.findIndex(handler => handler.id === subscriberUID);

		if (handlerIndex !== -1) {
			// remove from handlers
			subscriptionItem.handlers.splice(handlerIndex, 1);

			if (subscriptionItem.handlers.length === 0) {
				// unsubscribe from the channel, if it was the last handler
				console.log('[unsubscribeBars]: Unsubscribe from streaming. Channel:', channelString);
				socket.emit('SubRemove', { subs: [channelString] });
				channelToSubscription.delete(channelString);
				break;
			}
		}
	}
}
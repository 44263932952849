import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router-dom";
import TableView from '../../common/components/TableView';
import UserInfo from '../../common/components/UserInfo';
import InfoBox from '../../common/components/InfoBox';
import TradingChart from '../../common/components/TradingChart/TradingChart';
import { ProtocolAssetsTable, UserAssetsTable, TableData } from '../../common/types/Types';
import { APY_FORMAT, COMPACT_USD_FORMAT, m, USD_FORMAT } from '../../common/constants/constants';
import getConfig from '../../common/constants/config';
import { makeGraphQLRequest } from '../../utils';

const ENDPOINT = "https://lighthouse-sle-api-developer.azure-api.net/graphql/";
// const ENDPOINT = "http://0.0.0.0:8080/graphql/";
export const CONTRACT_ID = 'contract.main.burrow.near';

const config = getConfig('production');

const PROTOCOL_ASSETS_VARIABLES = {
  networkId: config.networkId,
  nodeUrl: config.nodeUrl,
  walletUrl: config.walletUrl,
  helperUrl: config.helperUrl,
  explorerUrl: config.explorerUrl,
  accountId: CONTRACT_ID
};

const PROTOCOL_ASSETS_QUERY = `
      query GetProtocolAssets(
          $networkId: String,
          $nodeUrl: String,
          $walletUrl: String,
          $helperUrl: String,
          $explorerUrl: String,
          $accountId: String
      ) {
          getProtocolAssets(
              networkId: $networkId,
              nodeUrl: $nodeUrl,
              walletUrl: $walletUrl,
              helperUrl: $helperUrl,
              explorerUrl: $explorerUrl,
              accountId: $accountId
          ) {
            borrowRows {
                symbol
                price
                supplyApy
                depositRewards {
                    metadata {
                        icon
                    }
                }
                borrowRewards {
                    metadata {
                        icon
                    }
                }
                totalSupply
                availableLiquidity
                collateralFactor
                deposited
                borrowed
            }
            depositRows {
                symbol
                price
                supplyApy
                depositRewards {
                    metadata {
                        icon
                    }
                }
                borrowRewards {
                    metadata {
                        icon
                    }
                }
                totalSupply
                availableLiquidity
                collateralFactor
                deposited
                borrowed
            }
        }
      }
  `;

  const USER_ASSETS_QUERY = `
      query GetUserPortfolio(
          $networkId: String,
          $nodeUrl: String,
          $walletUrl: String,
          $helperUrl: String,
          $explorerUrl: String,
          $accountId: String
      ) {
          getUserPortfolio(
              networkId: $networkId,
              nodeUrl: $nodeUrl,
              walletUrl: $walletUrl,
              helperUrl: $helperUrl,
              explorerUrl: $explorerUrl,
              accountId: $accountId
          ) {
          borrowed {
            tokenId
            name
            symbol
            price
            valueAmount
            dollarAmount
          }
          nonBorrowed {
            tokenId
            name
            symbol
            price
            valueAmount
            dollarAmount
          }
        }
      }
  `;

  const USER_ACCOUNT_BALANCE_QUERY = `
  query GetUserAccountBalance(
    $networkId: String,
    $nodeUrl: String,
    $walletUrl: String,
    $helperUrl: String,
    $explorerUrl: String,
    $accountId: String
) {
    getUserAccountBalance(
        networkId: $networkId,
        nodeUrl: $nodeUrl,
        walletUrl: $walletUrl,
        helperUrl: $helperUrl,
        explorerUrl: $explorerUrl,
        accountId: $accountId
    )
  }
  `

  const USER_ACCOUNT_INFO_QUERY = `
      query GetUserAccountInfo(
          $networkId: String,
          $nodeUrl: String,
          $walletUrl: String,
          $helperUrl: String,
          $explorerUrl: String,
          $accountId: String
      ) {
          getUserAccountInfo(
              networkId: $networkId,
              nodeUrl: $nodeUrl,
              walletUrl: $walletUrl,
              helperUrl: $helperUrl,
              explorerUrl: $explorerUrl,
              accountId: $accountId
          ) {
            netLiquidity
            netAPY
            healthFactor
            dailyRewards
        }
      }
  `;


const dollarUSLocale = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  useGrouping: true,
});

const infoBoxStyle = {
  flexBasis: '50%',
  padding: '6% 0% 6% 0%'
};

const userAssetsTableHeader = ['NAME', 'PRICE', 'TOTAL'];

const protocolAssetsTableHeader = {
  'borrow assets': ['NAME', 'APY', 'REWARDS', 'LIQUIDITY', 'C.F.', 'BORROWED'],
  'deposit assets': ['NAME', 'APY', 'REWARDS', 'DEPOSITS', 'LIQUIDITY', 'DEPOSITED']
};

type OwnProps = {
  accountId: string;
  theme: string;
};

type Props = OwnProps;

const HomePage: FC<Props> = ({ accountId, theme }) => {
  const [showBalance, setShowBalance] = useState<boolean>((window.localStorage.getItem('showBalance') === 'true'));
  const [accountBalance, setAccountBalance] = useState<string>('-');
  const [userAssetsTableSelected, setUserAssetsTableSelected] = useState<string>('non-borrowed');
  const [protocolAssetsTableSelected, setProtocolAssetsTableSelected] = useState<string>('borrow assets');
  const [showFullValue, setShowFullValue] = useState<boolean>(false);
  const [netLiquidity, setNetLiquidity] = useState<string>('$0');
  const [netAPY, setNetAPY] = useState<string>('0%');
  const [healthFactor, setHealthFactor] = useState<string>('0%');
  const [dailyRewards, setDailyRewards] = useState<string>('$0');
  const [userAssetsTable, setUserAssetsTable] = useState<UserAssetsTable>({
    'borrowed': [],
    'non-borrowed': [],
  });
  const [protocolAssetsTable, setProtocolAssetsTable] = useState<ProtocolAssetsTable>({
    'borrow assets': [],
    'deposit assets': [],
  });

  // const accountBalance = useAppSelector(getTotalAccountBalance);
  
  const USER_ASSETS_VARIABLES = {
    networkId: config.networkId,
    nodeUrl: config.nodeUrl,
    walletUrl: config.walletUrl,
    helperUrl: config.helperUrl,
    explorerUrl: config.explorerUrl,
    accountId
  };

  const clickShowBalance = useCallback(() => {
    setShowBalance(!showBalance);    
  }, [showBalance]);
  
  const location = useLocation();

  useEffect(()=>{
    window.localStorage.setItem('currPage',  location.pathname);
  },[location]);

  useEffect(() => {
      window.localStorage.setItem('showBalance', showBalance.toString());
  }, [showBalance]);

  useEffect(() => {
    geProtocolAssets(setProtocolAssetsTable);
  }, []);

  useEffect(() => {
    getUserAssets(setUserAssetsTable);
  }, []);

  useEffect(() => {
    getUserAccountBalance(setAccountBalance);
  }, []);
  
  useEffect(() => {
    getUserAccountInfo(setNetLiquidity, setNetAPY, setHealthFactor, setDailyRewards);
  }, []);

const geProtocolAssets = async (setProtocolAssetsTable) => {
    const protocolAssets = await makeGraphQLRequest(ENDPOINT, PROTOCOL_ASSETS_QUERY, PROTOCOL_ASSETS_VARIABLES);
    
    const borrowRows = protocolAssets.data.getProtocolAssets.borrowRows;
    const depositRows = protocolAssets.data.getProtocolAssets.depositRows;
    
    var protocolAssetsTable : ProtocolAssetsTable = {
      'borrow assets': formatAssetsTable(borrowRows, false),
      'deposit assets': formatAssetsTable(depositRows, true)
    };

    setProtocolAssetsTable(protocolAssetsTable);
}

const getUserAssets = async (setUserAssetsTable) => {
  const userAssets = await makeGraphQLRequest(ENDPOINT, USER_ASSETS_QUERY, USER_ASSETS_VARIABLES);

  const borrowed = userAssets.data.getUserPortfolio.borrowed;
  const nonBorrowed = userAssets.data.getUserPortfolio.nonBorrowed;

  var userAssetsTable : UserAssetsTable = {
    'non-borrowed': formatUserAssetsTable(borrowed),
    'borrowed': formatUserAssetsTable(nonBorrowed)
  };
  
  setUserAssetsTable(userAssetsTable);
}

const getUserAccountBalance = async (setAccountBalance) => {
  const balance = await makeGraphQLRequest(ENDPOINT, USER_ACCOUNT_BALANCE_QUERY, USER_ASSETS_VARIABLES); 
  const accountBalance = balance.data.getUserAccountBalance > 999999 ? `$${m(balance.data.getUserAccountBalance)}` : balance.data.getUserAccountBalance.toLocaleString(undefined, USD_FORMAT);
  setAccountBalance(accountBalance);
}

const getUserAccountInfo = async (setNetLiquidity, setNetAPY, setHealthFactor, setDailyRewards) => {
  const accountInfo = await makeGraphQLRequest(ENDPOINT, USER_ACCOUNT_INFO_QUERY, USER_ASSETS_VARIABLES); 
  
  const { netLiquidity, netAPY, healthFactor, dailyRewards } = accountInfo.data.getUserAccountInfo;
  const color =
    healthFactor === -1 || healthFactor === null
      ? "rgba(172, 255, 209, 1)"
      : healthFactor < 180
      ? "pink"
      : healthFactor < 200
      ? "orange"
      : "rgba(172, 255, 209, 1)";

  const label =
    healthFactor === -1 || healthFactor === null
      ? "n/a"
      : healthFactor < 180
      ? "Low"
      : healthFactor < 200
      ? "Medium"
      : "Good";

  setHealthFactor(healthFactor === -1 || healthFactor === null
    ? "N/A"
    : `${healthFactor?.toLocaleString(undefined, {
        maximumFractionDigits: healthFactor <= 105 ? 2 : 0,
      })}%`);
  setNetLiquidity(showFullValue
      ? netLiquidity.toLocaleString(undefined, COMPACT_USD_FORMAT)
      : `$${m(netLiquidity)}`);
  setNetAPY(`${(netAPY).toLocaleString(undefined, APY_FORMAT)}%`);
  setDailyRewards(dailyRewards.toLocaleString(undefined, USD_FORMAT));
}

  const formatAssetsTable = ((assets, isDeposit) => {
    let assetList : TableData[][] = [];
    assets.forEach(async (asset) => {  
      var rewards = isDeposit ? asset.depositRewards : asset.borrowRewards;
      let obj = [
        {
          sortValue: asset.symbol,
          value: formatAssetNameColumn(asset.symbol, asset.price)
        },
        {
          sortValue: asset.supplyApy,
          value: `${(asset.supplyApy).toLocaleString(undefined, APY_FORMAT)}%`
        }, 
        {
          sortValue: isDeposit ? asset.depositRewards.length : asset.borrowRewards.length,
          value: "",
          components: formatAssetRewardsColumn(rewards)
        }, 
        {
          sortValue: isDeposit ? asset.totalSupply : asset.availableLiquidity,
          value: isDeposit ? `${m(asset?.totalSupply)}` : `${m(asset?.availableLiquidity)}`
        }, 
        {
          sortValue: isDeposit ? asset.availableLiquidity : parseFloat(asset.collateralFactor) / 100.0,
          value: isDeposit ? `${m(asset?.availableLiquidity)}` : asset.collateralFactor
        }, 
        {
          sortValue: isDeposit ? asset.deposited : asset.borrowed,
          value: isDeposit ? `${m(asset?.deposited)}` : `${m(asset?.borrowed)}`
        }, 
      ]
      assetList.push(obj);
    });    
    
    return assetList;
  });

  const formatAssetNameColumn = (
    symbol,
    price
  ): string => {  
    return `${symbol.toUpperCase()}\n${price.toLocaleString(undefined, USD_FORMAT)}`
  };
  
  const formatAssetRewardsColumn = (rewards): any[] => {  
    var rewardComponents = [];
    rewards.forEach(reward => {
      rewardComponents.push(<div><img style="border-radius: 50%;overflow: hidden;" width="30" height="30" src={reward.metadata.icon}/></div>)
    });
    return rewardComponents;
  };

  const onChangeFilter = useCallback((e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    const value = e.target.innerText.toLowerCase();
    if (userAssetsTable[value]) {
      setUserAssetsTableSelected(value);
    } else if (protocolAssetsTable[value]) {
      setProtocolAssetsTableSelected(value);
    }
  }, []);

  const formatPortfolioNameColumn = (
    name: string,
    symbol: string,
  ): string => {  
    return `${name.toUpperCase()}\n${symbol.toUpperCase()}`;
  };

  const formatPortfolioPriceColumn = (
    currPrice: string,
    currChange1hr: string,
  ): string => {  
    return `${currPrice}\n${currChange1hr ? currChange1hr : 0}`;
  };

  const formatPortfolioTotalColumn = (
    valueAmount: number,
    dollarAmount: number,
    symbol: string,
  ): string => {  
    
    return `${dollarUSLocale.format(dollarAmount)}\n${(valueAmount ? valueAmount.toFixed(2) : 0) + " " + symbol.toUpperCase()}`;
  };

  const formatUserAssetsTable = ((assets) => {    
    let assetList : TableData[][] = [];
    Object.values(assets).forEach(async (asset) => {  
      let obj = [
        {
          sortValue: asset.name,
          value: formatPortfolioNameColumn(asset.name, asset.symbol)
        },
        {
          sortValue: Number(asset.price),
          value: formatPortfolioPriceColumn(dollarUSLocale.format(asset.price), 0)
        }, 
        {
          sortValue: Number(asset.dollarAmount),
          value: formatPortfolioTotalColumn(asset.valueAmount, asset.dollarAmount, asset.symbol)
        },
      ]
      assetList.push(obj);
    });    
    
    return assetList;
  });

  const protocolAssetsTableSelectedAction = (() => {
    const borrowLink = 'https://app.burrow.cash/borrow/';
    const depositLink = 'https://app.burrow.cash/deposit/';

    if(protocolAssetsTableSelected == 'borrow assets') {
      window.open(borrowLink);
    } else if(protocolAssetsTableSelected == 'deposit assets') {
      window.open(depositLink);
    }    
  });

  const chartProps = {
    symbol: 'NEAR',
    interval: '1',
    libraryPath: '/charting_library/',
    chartsStorageUrl: 'https://saveload.tradingview.com',
    chartsStorageApiVersion: '1.1',
    clientId: 'tradingview.com',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
    timeframe: '1D'
  }

  return (
    <>
      <div
        style={{
          padding: '125px 10% 3% 10%'
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            flexFlow: 'wrap',
            textAlign: '-webkit-center',
            paddingBottom: '10px',
            alignItems: 'center'
          }}>
          <div
            style={{
              display: 'flex',
              flex: '2 1 0',
              alignItems: 'normal'
            }}>
            <div
              style={{
                flex: 1
              }}>
              <UserInfo
                showBalance={showBalance}
                accountBalance={accountBalance}
                onClick={() => clickShowBalance()}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flex: '2 1 0',
              flexWrap: 'wrap',
              justifyContent: 'center',
              padding: '0px 10px 0px 10px',
              minWidth: '370px',
            }}>
            <div style={infoBoxStyle}>
              <InfoBox divisor={1.5} title={'WEIGHTED NET LIQUIDITY'} value={netLiquidity} page={'analytics'}/>
            </div>
            <div style={infoBoxStyle}>
              <InfoBox divisor={1.5} title={'APY'} value={netAPY} page={'analytics'}/>
            </div>
            <div style={infoBoxStyle}>
              <InfoBox divisor={1.5} title={'DAILY REWARDS'} value={dailyRewards} page={'analytics'}/>
            </div>
            <div style={infoBoxStyle}>
              <InfoBox divisor={1.5} title={'HEALTH FACTOR'} value={healthFactor} page={'analytics'}/>
            </div>
          </div>
          <div
            style={{
              flex: '3 2 0',
              alignSelf: 'center',
              minWidth: '300px'
            }}>
            <TradingChart hideSideToolbar={false} showLineGraph={true} isAnalyticsPage={false} datafeedUrl={''} container={undefined} theme={theme} {...chartProps}/>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            gap: '1.5%'
          }}>
          <div
            style={{
              flex: 1,
              paddingTop: '20px',
              minWidth: '300px'
            }}>
            <TableView
              tableOptions={userAssetsTable}
              theadData={userAssetsTableHeader}
              selected={userAssetsTableSelected}
              onChangeFilter={onChangeFilter}
              numRows={6}
            />
          </div>
          <div
            style={{
              flex: 2,
              paddingTop: '20px',
              minWidth: '300px'
            }}>
            <TableView
              tableOptions={protocolAssetsTable}
              theadData={protocolAssetsTableHeader[protocolAssetsTableSelected]}
              selected={protocolAssetsTableSelected}
              selectedAction={protocolAssetsTableSelectedAction}
              onChangeFilter={onChangeFilter}
              numRows={6}
            />
          </div>
        </div>
      </div>
    </>
  )
};

export default HomePage;
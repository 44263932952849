import {
	makeGraphQLRequest,
} from '../../../utils';

const channelToSubscription = new Map();
const lastBarsCache = new Map();
const configurationData = {
	supported_resolutions: ['1', '5', '15', '30', '45', '60', '240', 'D', '1W', '1M', '3M', '6M', '12M', '60M'],
};
// const ENDPOINT = "http://0.0.0.0:8080/graphql/";
const ENDPOINT = "https://lighthouse-sle-api-developer.azure-api.net/graphql/";
const PRICES_QUERY = `
  {
    getPrices {
      price
			timestamp
    }
  }
`;

const symbols = [{
	symbol: "NEAR",
	full_name: "NEAR Protocol",
	description: "",
	exchange: "NEAR",
	type: "NEAR",
	ticker: "NEAR"
}];

export default {
    onReady: (callback) => {
		console.log('[onReady]: Method call');
		setTimeout(() => callback(configurationData));
    },
    searchSymbols: async (
			userInput,
			exchange,
			symbolType,
			onResultReadyCallback,
		) => {
		console.log('[searchSymbols]: Method call');
		onResultReadyCallback(symbols);
    },
    resolveSymbol: async (
			symbolName,
			onSymbolResolvedCallback,
			onResolveErrorCallback,
			extension
		) => {
		const symbolInfo = {
			ticker: "NEAR",
			name: "NEAR",
			description: "NEAR Protocol",
			type: "NEAR",
			session: '24x7',
			timezone: 'Etc/UTC',
			exchange: "NEAR",
			minmov: 1,
			pricescale: .01,
			has_intraday: true,
			intraday_multipliers: ['1', '5', '15', '30', '45'],
			has_daily: true,
			daily_multipliers: ['1'],
			has_weekly_and_monthly: true,
			weekly_multipliers: ['1'],
			monthly_multipliers: ['1', '3', '6', '12', '60'],
			// seconds_multipliers: ['1'],
			// has_seconds: true,
			// has_no_volume: true,
			supported_resolutions: configurationData.supported_resolutions,
			volume_precision: 2,
			data_status: 'streaming',
		};

		console.log('[resolveSymbol]: Symbol resolved', symbolName);
		onSymbolResolvedCallback(symbolInfo);
    },
    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
			const { from, to, firstDataRequest } = periodParams;
			console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
			const parsedSymbol = "NEAR";
			const urlParameters = {
				e: parsedSymbol.exchange,
				fsym: parsedSymbol.fromSymbol,
				tsym: parsedSymbol.toSymbol,
				toTs: to,
				limit: 2000,
			};
			const query = Object.keys(urlParameters)
				.map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
				.join('&');
			try {
				const data = await makeGraphQLRequest(ENDPOINT, PRICES_QUERY);		
					
				if (!data || data.length === 0) {
					// "noData" should be set if there is no data in the requested period.
					onHistoryCallback([], {
						noData: true,
					});
					return;
				}
				let bars = [];
				data.data.getPrices.map(d => {	
					let time = d.timestamp;					
					if (time / 1000 >= from && time / 1000 < to) {
						bars = [...bars, {
							time: time,
							open: d.price,
							high: d.price,
							low: d.price,
							close: d.price,
						}];
					}
				});
				if (firstDataRequest) {
					lastBarsCache.set(symbolInfo.full_name, {
						...bars[bars.length - 1],
					});
				}

				console.log(`[getBars]: returned ${bars.length} bar(s)`);
				onHistoryCallback(bars, {
					noData: false,
				});
			} catch (error) {
				console.log('[getBars]: Get error', error);
				onErrorCallback(error);
			}
		},
    subscribeBars: (
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscriberUID,
			onResetCacheNeededCallback,
		) => {
        console.log('[subscribeBars]: Method call with subscribeUID:', subscriberUID);
		subscribeOnStream(
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscriberUID,
			onResetCacheNeededCallback,
			lastBarsCache.get(symbolInfo.full_name),
		);
		},
    unsubscribeBars: (subscriberUID) => {
        console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
		unsubscribeFromStream(subscriberUID);
    },
};

function subscribeOnStream(
	symbolInfo,
	resolution,
	onRealtimeCallback,
	subscribeUID,
	onResetCacheNeededCallback,
	lastDailyBar,
) {
	const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
	const channelString = `0~${parsedSymbol.exchange}`;
		// const channelString = `0~${parsedSymbol.exchange}~${parsedSymbol.fromSymbol}~${parsedSymbol.toSymbol}`;
	const handler = {
		id: subscribeUID,
		callback: onRealtimeCallback,
	};
	let subscriptionItem = channelToSubscription.get(symbolInfo.full_name);
	if (subscriptionItem) {
		// already subscribed to the channel, use the existing subscription
		subscriptionItem.handlers.push(handler);
		return;
	}
	subscriptionItem = {
		subscribeUID,
		resolution,
		lastDailyBar,
		handlers: [handler],
	};
	channelToSubscription.set(channelString, subscriptionItem);
	console.log('[subscribeBars]: Subscribe to streaming. Channel:', channelString);
	socket.emit('SubAdd', { subs: [channelString] });
}

function unsubscribeFromStream(subscriberUID) {
	// find a subscription with id === subscriberUID
	for (const channelString of channelToSubscription.keys()) {
		const subscriptionItem = channelToSubscription.get(channelString);
		const handlerIndex = subscriptionItem.handlers
			.findIndex(handler => handler.id === subscriberUID);

		if (handlerIndex !== -1) {
			// remove from handlers
			subscriptionItem.handlers.splice(handlerIndex, 1);

			if (subscriptionItem.handlers.length === 0) {
				// unsubscribe from the channel, if it was the last handler
				console.log('[unsubscribeBars]: Unsubscribe from streaming. Channel:', channelString);
				socket.emit('SubRemove', { subs: [channelString] });
				channelToSubscription.delete(channelString);
				break;
			}
		}
	}
}

function parseFullSymbol(fullSymbol) {
	const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
	if (!match) {
		return null;
	}

	return {
		exchange: match[1],
		fromSymbol: match[2],
		toSymbol: match[3],
	};
}
// React
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

// NEAR
// import { Contract } from './near-interface';
import { Wallet } from './near-wallet';
import { BrowserRouter } from 'react-router-dom';

// When creating the wallet you can optionally ask to create an access key
// Having the key enables to call non-payable methods without interrupting the user to sign
const wallet = new Wallet({ createAccessKeyFor: process.env.CONTRACT_NAME });

// Abstract the logic of interacting with the contract to simplify your flow
// const contract = new Contract({ contractId: process.env.CONTRACT_NAME, walletToUse: wallet });

// Setup on page load
window.onload = async () => {
  const isSignedIn = await wallet.startUp();
  const root = createRoot(document.getElementById('root'));

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App isSignedIn={isSignedIn} wallet={wallet} />
      </BrowserRouter>
    </React.StrictMode>);
};

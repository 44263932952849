import React, { FC } from 'react';
import { TableData } from '../types/Types';
import styled from 'styled-components';

interface Prop {
  data: TableData[];
  onClick?: Function;
}

const StyledTableRow = styled.tr`
  color: ${props => props.theme.tableRowTextColor};
  text-align: center;
  font-family: Cantarell, sans-serif;
  font-weight: 700;
  letter-spacing: 2%;
  font-size: 12px;
  height: 50px;
`;

const StyledLink = styled.a`
  color: ${props => props.theme.tableRowTextColor};
  
  &:visited {
    color: ${props => props.theme.tableRowTextColor};
  }
  
  &:hover {
    color: ${props => props.theme.tableHoverTextColor};
  }
  
  &:active {
    color: ${props => props.theme.tableRowTextColor};
  }
`;

const TableRow: FC<Prop> = ({ data, onClick }) => {  
  return (
    <StyledTableRow onClick={onClick}>
      {data.map((item) => {
        return (
          <td key={item.value}>
            {item.link && <StyledLink href={item.link} target={"_blank"}>{item.link && item.value && item.value.split(/\r?\n|\r|\n/g)[0]}</StyledLink>}
            {!item.link && item.value && item.value.split(/\r?\n|\r|\n/g)[0]}
            <br />
            {!item.link && item.value && item.value.split(/\r?\n|\r|\n/g)[1]}
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}>
              {item.components && item.components.map((component) => (
                component
              ))}
            </div>
          </td>
        );
      })}
    </StyledTableRow>
  );
};

export default TableRow;

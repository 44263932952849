import React, { FC } from 'react';
import styled from 'styled-components';

interface Prop {
    item: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    currentSort: Object;
}

const TableHeader = styled.th`
    position: sticky;
    text-align: center;
    font-family: Cantarell, sans-serif;
    font-weight: 700;
    letter-spacing: 2%;
    font-size: 12px;
    height: 50px;
    min-width: 100px;
    white-space: pre-wrap;

  color: ${props => props.theme.headerTextColor};

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

const SortButton = styled.button`
    color: ${props => props.theme.tableRowTextColor}; 
    background: none; 
    border: none;
`
const TableHeadItem: FC<Prop> = ({ item, onClick, currentSort }) => {
    return (
        <TableHeader
            title={item}>
            {item}
            <SortButton onClick={onClick}>
                <i className={`fas fa-${currentSort.class}`} />
            </SortButton>
        </TableHeader>
    );
};

export default TableHeadItem;
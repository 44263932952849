/* A helper file that simplifies using the wallet selector */

// near api js
import * as nearAPI from 'near-api-js';
const { utils, Contract } = nearAPI;
// wallet selector UI
import '@near-wallet-selector/modal-ui/styles.css';
import { setupModal } from '@near-wallet-selector/modal-ui';
import LedgerIconUrl from '@near-wallet-selector/ledger/assets/ledger-icon.png';
import MyNearIconUrl from '@near-wallet-selector/my-near-wallet/assets/my-near-wallet-icon.png';
import NearIconUrl from '@near-wallet-selector/near-wallet/assets/near-wallet-icon.png';
// import NearfiIconUrl from '@near-wallet-selector/nearfi/assets/nearfi-icon.png';
// import SenderIconUrl from '@near-wallet-selector/sender/assets/sender-icon.png';
// import WalletConnectIconUrl from '@near-wallet-selector/wallet-connect/assets/wallet-connect-icon.png';
// import HereWalletIconUrl from '@near-wallet-selector/here-wallet/assets/here-wallet-icon.png';
import XDEFIIconUrl from '@near-wallet-selector/xdefi/assets/xdefi-icon.png';
// import NightlyIconUrl from '@near-wallet-selector/nightly/assets/nightly.png';
// import NightlyConnectIconUrl from '@near-wallet-selector/nightly-connect/assets/nightly-connect.png';
// import MeteorIconUrl from '@near-wallet-selector/meteor-wallet/assets/meteor-icon.png';

// wallet selector options
import { setupWalletSelector } from '@near-wallet-selector/core';
import { setupLedger } from '@near-wallet-selector/ledger';
import { setupMyNearWallet } from '@near-wallet-selector/my-near-wallet';
import { setupNearWallet } from '@near-wallet-selector/near-wallet';
// import { setupNearFi } from '@near-wallet-selector/nearfi';
// import { setupSender } from '@near-wallet-selector/sender';
// import { setupWalletConnect } from '@near-wallet-selector/wallet-connect';
// import { setupHereWallet } from "@near-wallet-selector/here-wallet";
import { setupXDEFI } from "@near-wallet-selector/xdefi";
// import { setupNightly } from "@near-wallet-selector/nightly";
// import { setupNightlyConnect } from "@near-wallet-selector/nightly-connect";
// import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet";
// import { setupNeth } from "@near-wallet-selector/neth";

import { BurrowContractData, NEARContractData } from './common/constants/contracts';
const THIRTY_TGAS = '30000000000000';
const NO_DEPOSIT = '0';
// const BURROW_CONTRACT = 'contract.1638481328.burrow.testnet';
const BURROW_CONTRACT = 'contract.main.burrow.near';
const PROTOCOL_ACCOUNT_ID = 'linear-protocol.near';

// Wallet that simplifies using the wallet selector
export class Wallet {
  walletSelector;
  wallet;
  network;
  createAccessKeyFor;

  constructor({ createAccessKeyFor = undefined, network = 'mainnet' }) {
    // Login to a wallet passing a contractId will create a local
    // key, so the user skips signing non-payable transactions.
    // Omitting the accountId will result in the user being
    // asked to sign all transactions.
    this.createAccessKeyFor = createAccessKeyFor
    this.network = network
  }

  // To be called when the website loads
  async startUp() {
    const { connect, keyStores } = nearAPI;
    const myKeyStore = new keyStores.BrowserLocalStorageKeyStore();
    
    this.walletSelector = await setupWalletSelector({
      network: this.network,
      modules: [
        setupNearWallet({ iconUrl: NearIconUrl }), 
        setupXDEFI({ iconUrl: XDEFIIconUrl }),
        // setupSender({ iconUrl: SenderIconUrl }), 
        // setupWalletConnect({ iconUrl: WalletConnectIconUrl }),
        // setupHereWallet({ iconUrl: HereWalletIconUrl }),
        // setupNightly({ iconUrl: NightlyIconUrl }), 
        // setupNightlyConnect({ iconUrl: NightlyConnectIconUrl }), 
        // setupNeth(),
        // setupNearFi({ iconUrl: NearfiIconUrl }), 
        setupMyNearWallet({ iconUrl: MyNearIconUrl }), 
        // setupMeteorWallet({ iconUrl: MeteorIconUrl }),
        setupLedger({ iconUrl: LedgerIconUrl }),
      ],
    });

    const isSignedIn = this.walletSelector.isSignedIn();

    if (isSignedIn) {
      const { network } = this.walletSelector.options;
      this.connectionConfig = {
        networkId: network.networkId,
        keyStore: myKeyStore, // first create a key store
        nodeUrl: network.nodeUrl,
        walletUrl: network.walletUrl,
        helperUrl: network.helperUrl,
        explorerUrl: network.explorerUrl,
      };
      this.wallet = await this.walletSelector.wallet();
      this.accountId = this.walletSelector.store.getState().accounts[0].accountId;
      this.protocolAccountId = PROTOCOL_ACCOUNT_ID;
      const nearConnection = await connect(this.connectionConfig);
      this.account = await nearConnection.account(this.accountId);
      this.burrowContract = new Contract(
        this.account,
        BURROW_CONTRACT,
        {
          viewMethods: ["get_account", "get_asset", "get_assets_paged", "get_config", "get_asset_farm"],
        }
      );
    
      this.config = await this.burrowContract.get_config();
    
      this.oracleContract = new Contract(
        this.account,
        this.config.oracle_account_id,
        {
          viewMethods: ["get_price_data"],
          changeMethods: ["oracle_call"],
        }
      );
    }

    return isSignedIn;
  }

  // Sign-in method
  async signIn() {
    const description = 'Please select a wallet to sign in.';
    const modal = setupModal(this.walletSelector, { contractId: this.createAccessKeyFor, description });
    modal.show();
    // this.connectionConfig = {
    //   networkId: network.networkId,
    //   keyStore: myKeyStore, // first create a key store
    //   nodeUrl: network.nodeUrl,
    //   walletUrl: network.walletUrl,
    //   helperUrl: network.helperUrl,
    //   explorerUrl: network.explorerUrl,
    // };
    // this.wallet = await this.walletSelector.wallet();
  }

  // Sign-out method
  async signOut() {
    await this.wallet.signOut();
    this.wallet = this.accountId = this.createAccessKeyFor = null;
    window.location.replace(window.location.origin + window.location.pathname);
  }

  // Make a read-only call to retrieve information from the network
  async viewMethod({ contractId, method, args = {} }) {
    const { network } = this.walletSelector.options;
    const provider = new providers.JsonRpcProvider({ url: network.nodeUrl });

    let res = await provider.query({
      request_type: 'call_function',
      account_id: contractId,
      method_name: method,
      args_base64: Buffer.from(JSON.stringify(args)).toString('base64'),
      finality: 'optimistic',
    });
    return JSON.parse(Buffer.from(res.result).toString());
  }

  // Call a method that changes the contract's state
  async callMethod({ contractId, method, args = {}, gas = THIRTY_TGAS, deposit = NO_DEPOSIT }) {
    // Sign a transaction with the "FunctionCall" action
    return await this.wallet.signAndSendTransaction({
      signerId: this.accountId,
      receiverId: contractId,
      actions: [
        {
          type: 'FunctionCall',
          params: {
            methodName: method,
            args,
            gas,
            deposit,
          },
        },
      ],
    });
  }

  // Get transaction result from the network
  async getTransactionResult(txhash) {
    const { network } = this.walletSelector.options;
    const provider = new providers.JsonRpcProvider({ url: network.nodeUrl });

    // Retrieve transaction result from the network
    const transaction = await provider.txStatus(txhash, 'unnused');
    return providers.getTransactionLastResult(transaction);
  }
}
import React, { FC } from 'react';
import styled from 'styled-components';

import 'tippy.js/dist/tippy.css'; // optional

interface Prop {
  title: string;
  value: string;
  tooltip?: string;
  width?: number;
  divisor?: number;
  page?: string;
}

const InfoDivTooltipText = styled.span`
  // position: absolute;
  top: 0;
  // z-index: 1;
  color: #fff;
  padding: 10px 18px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 25px;
  // opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
  // transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);

  background: ${props => props.theme.infoBoxColor};

  &:before{
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    background: ${props => props.theme.infoBoxColor};
    left: 50%;
    bottom: -6px;
    transform: translateX(-50%) rotate(45deg);
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
`;

const InfoDivTooltip = styled.div`
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;

  &:hover ${InfoDivTooltipText} {
    top: -70px;
    opacity: 1;
    pointer-events: auto;
  }
`;

const InfoDivDataTextContainer = styled.div`
  width: 100%;
  text-align: center;
  height: 50%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoDivDataText = styled.span`
  font-family: Cantarell, sans-serif;
  font-weight: 700;
  letter-spacing: 2%;
  font-size: 12px;
  padding: 0 1% 0 1%;
  
  color: ${props => props.theme.infoDataColor};

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 450px) {
    font-size: 10px;
  }
`;

const InfoDivTextContainer = styled.div`
  width: 100%;
  text-align: center;
  height: 55%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const InfoDivText = styled.span`
  font-family: Cantarell, sans-serif;
  font-weight: 700;
  letter-spacing: 2%;
  font-size: 215%;
  padding: 0 3% 0 3%;

  color: ${props => props.theme.infoColor};

  @media (max-width: 768px) {
    font-size: 150%;
  }
`;

const InfoDivBoxContainer = styled.div`
  height: 103px;
  border-radius: 15px;
  position: relative;
  // z-index: 2;
  // transition: 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  
  width: ${props => (props.width / props.divisor) + 'px' };
  box-shadow: ${props => props.theme.boxShadow};
  background-color: ${props => props.theme.infoBoxColor};
  
  &:hover ${InfoDivTooltipText} {
    visibility: visible;
  }

  &:hover {
    background-color: ${props => props.theme.tableHoverColor};
  }

  &:hover ${InfoDivDataText} {
    color: ${props => props.theme.walletConnectColor};
  }

  &:hover ${InfoDivText} {
    color: ${props => props.theme.infoColor};
  }

  @media (min-width: 768px) {
    height: 155px;
    width: ${props => props.width + 'px' };
  }
`;

const InfoBox: FC<Prop> = ( {width = 169, divisor = 1, title, value, page, tooltip }) => {
  return (
    <>
      <div>
        {/* <Tippy content={tooltip}> */}
        <InfoDivBoxContainer width={width} divisor={divisor}>
          <InfoDivDataTextContainer>
            <InfoDivDataText>
              {title}
            </InfoDivDataText>
          </InfoDivDataTextContainer>
          <InfoDivTextContainer page={page}>
            <InfoDivText>
              {value}
            </InfoDivText>
          </InfoDivTextContainer>
        </InfoDivBoxContainer>
        {/* </Tippy> */}
      </div>
    </>
  );
};

export default InfoBox;

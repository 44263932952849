import { useEffect, useState } from 'react';

export const useDarkMode = () : [string, ()=>void] => {    
    const [theme, setTheme] = useState(window.localStorage.getItem('theme') === null ? 'Dark' : window.localStorage.getItem('theme'));

    useEffect(() => {
        window.localStorage.setItem('theme', theme);
    }, [theme]);
    
    const toggleTheme = () => {
        theme === 'Dark' ? setTheme('Light') : setTheme('Dark');
    };

    return [theme, toggleTheme]
}
export const NEARContractData = [
    {
        contract: "weth",
        contractName: "wrapped ethereum",
        stakeContract: "weth.alchemist35.testnet",
        tokenContract: "weth.fakes.testnet",
        coingeckoId: "weth",
        type: "non-bonded",
        decimals: 18
    },
    {
        contract: "near",
        contractName: "NEAR Protocol",
        stakeContract: "near.alchemist35.testnet",
        tokenContract: "wrap.testnet",
        coingeckoId: "near",
        type: "non-bonded",
        decimals: 24
    },
    {
        contract: "wnear",
        contractName: "wrapped near",
        stakeContract: "wnear.alchemist35.testnet",
        tokenContract: "wrap.testnet",
        coingeckoId: "wrapped-near",
        type: "non-bonded",
        decimals: 24
    },
    {
        contract: "aurora",
        contractName: "aurora",
        stakeContract: "aurora.alchemist35.testnet",
        tokenContract: "aurora.fakes.testnet",
        coingeckoId: "aurora-near",
        type: "non-bonded",
        decimals: 18
    },
    {
        contract: "dai",
        contractName: "dai",
        stakeContract: "dai.alchemist35.testnet",
        tokenContract: "dai.fakes.testnet",
        coingeckoId: "dai",
        type: "non-bonded",
        decimals: 18
    },
    {
        contract: "usdt",
        contractName: "tether",
        stakeContract: "usdt.alchemist35.testnet",
        tokenContract: "usdt.fakes.testnet",
        coingeckoId: "tether",
        type: "non-bonded",
        decimals: 6
    },
    {
        contract: "usn",
        contractName: "usn",
        stakeContract: "usn.alchemist35.testnet",
        tokenContract: "usn.fakes.testnet",
        coingeckoId: "usn",
        type: "non-bonded",
        decimals: 18
    },
    {
        contract: "usdc",
        contractName: "usd coin",
        stakeContract: "usdc.alchemist35.testnet",
        tokenContract: "usdc.fakes.testnet",
        coingeckoId: "usd-coin",
        type: "non-bonded",
        decimals: 6
    },
    {
        contract: "wbtc",
        contractName: "wrapped bitcoin",
        stakeContract: "wbtc.alchemist35.testnet",
        tokenContract: "wbtc.fakes.testnet",
        coingeckoId: "wrapped-bitcoin",
        type: "non-bonded",
        decimals: 8
    },
    {
        contract: "stNear",
        contractName: "staked near",
        stakeContract: "stnear.alchemist35.testnet",
        tokenContract: "stnear.fakes.testnet",
        coingeckoId: "staked-near",
        type: "non-bonded",
        decimals: 24
    },
];


export const BurrowContractData = {
    "weth.fakes.testnet": {
        contract: "weth",
        contractName: "wrapped ethereum",
        stakeContract: "weth.alchemist35.testnet",
        tokenContract: "weth.fakes.testnet",
        coingeckoId: "weth",
        type: "non-bonded",
        decimals: 18
    },
    "wrap.testnet": {
        contract: "near",
        contractName: "NEAR Protocol",
        stakeContract: "near.alchemist35.testnet",
        tokenContract: "wrap.testnet",
        coingeckoId: "near",
        type: "non-bonded",
        decimals: 24
    },
    "wrap.testnet": {
        contract: "wnear",
        contractName: "wrapped near",
        stakeContract: "wnear.alchemist35.testnet",
        tokenContract: "wrap.testnet",
        coingeckoId: "wrapped-near",
        type: "non-bonded",
        decimals: 24
    },
    "aurora.fakes.testnet": {
        contract: "aurora",
        contractName: "aurora",
        stakeContract: "aurora.alchemist35.testnet",
        tokenContract: "aurora.fakes.testnet",
        coingeckoId: "aurora-near",
        type: "non-bonded",
        decimals: 18
    },
    "dai.fakes.testnet": {
        contract: "dai",
        contractName: "dai",
        stakeContract: "dai.alchemist35.testnet",
        tokenContract: "dai.fakes.testnet",
        coingeckoId: "dai",
        type: "non-bonded",
        decimals: 18
    },
    "usdt.fakes.testnet": {
        contract: "usdt",
        contractName: "tether",
        stakeContract: "usdt.alchemist35.testnet",
        tokenContract: "usdt.fakes.testnet",
        coingeckoId: "tether",
        type: "non-bonded",
        decimals: 6
    },
    "usn.fakes.testnet": {
        contract: "usn",
        contractName: "usn",
        stakeContract: "usn.alchemist35.testnet",
        tokenContract: "usn.fakes.testnet",
        coingeckoId: "usn",
        type: "non-bonded",
        decimals: 18
    },
    "usdc.fakes.testnet": {
        contract: "usdc",
        contractName: "usd coin",
        stakeContract: "usdc.alchemist35.testnet",
        tokenContract: "usdc.fakes.testnet",
        coingeckoId: "usd-coin",
        type: "non-bonded",
        decimals: 18
    },
    "wbtc.fakes.testnet": {
        contract: "wbtc",
        contractName: "wrapped bitcoin",
        stakeContract: "wbtc.alchemist35.testnet",
        tokenContract: "wbtc.fakes.testnet",
        coingeckoId: "wrapped-bitcoin",
        type: "non-bonded",
        decimals: 8
    },
    "stnear.fakes.testnet": {
        contract: "stNear",
        contractName: "staked near",
        stakeContract: "stnear.alchemist35.testnet",
        tokenContract: "stnear.fakes.testnet",
        coingeckoId: "staked-near",
        type: "non-bonded",
        decimals: 24
    },
};
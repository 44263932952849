import React, { FC, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";

import { TopLiquidatingTable, HistLiquidationsTable, TableData, HighestRiskLoansTable } from '../../common/types/Types';
import InfoBox from '../../common/components/InfoBox';
import TableView from '../../common/components/TableView';
import TradingChart from '../../common/components/TradingChart/TradingChart';
import { APY_FORMAT, m, USD_FORMAT } from '../../common/constants/constants';
import { makeGraphQLRequest, shrinkToken, sumRewards } from '../../utils';
import getConfig from '../../common/constants/config';

const ENDPOINT = "https://lighthouse-sle-api-developer.azure-api.net/graphql/";
// const ENDPOINT = "http://0.0.0.0:8080/graphql/";
const CONTRACT_ID = 'contract.main.burrow.near';

const config = getConfig('production');

const VARIABLES = {
    networkId: config.networkId,
    nodeUrl: config.nodeUrl,
    walletUrl: config.walletUrl,
    helperUrl: config.helperUrl,
    explorerUrl: config.explorerUrl,
    limit: 10,
    accountId: CONTRACT_ID,
    liquidated: false
};

const HIGH_RISK_LOANS_QUERY = `
    query GetHighestRiskLoans(
        $limit: Int,
        $liquidated: Boolean!
    ) {
        getHighestRiskLoans(
            limit: $limit,
            liquidated: $liquidated
        ) {
            accountId
            adjustedBorrowedSum
            adjustedCollateralSum
            adjustedDebt
            healthFactor
        }
    }
`;

const TOP_LIQUIDATING_ACCOUNTS_QUERY = `
{
    getTopLiquidatingAccounts (
        eventType: 1,
        limit: 10
    ) {
        accountId,
        totalLiquidationAmount,
        averageDiscount
    }
}
`;

const AVERAGE_LIQUIDATION_DATA_QUERY = `
{
    getLiquidationData (
        eventType: 1
    ) {
        averageDiscount,
        averageLiquidationSize
    }
}
`;

const HISTORICAL_LIQUIDATIONS_QUERY = `
{
    getHistoricalLiquidations (eventType: 1, limit: 25) {
        eventTimestamp,
        eventType,
        actingAccountId,
        liquidationAccountId,
        collateralSum,
        repaidSum,
        amount,
        receiptId,
        predecessorId,
    }
}
`;

const PROTOCOL_ASSET_INFO_QUERY = `
    query GetProtocolAssetInfo(
        $networkId: String,
        $nodeUrl: String,
        $walletUrl: String,
        $helperUrl: String,
        $explorerUrl: String,
        $accountId: String
    ) {
        getProtocolAssetInfo(
            networkId: $networkId,
            nodeUrl: $nodeUrl,
            walletUrl: $walletUrl,
            helperUrl: $helperUrl,
            explorerUrl: $explorerUrl,
            accountId: $accountId
        ) {
            protocolBorrowed
            protocolDeposited
        }
    }
`;

const PROTOCOL_NET_LIQUIDITY_QUERY = `
    query GetProtocolNetLiquidity(
        $networkId: String,
        $nodeUrl: String,
        $walletUrl: String,
        $helperUrl: String,
        $explorerUrl: String,
        $accountId: String
    ) {
        getProtocolNetLiquidity(
            networkId: $networkId,
            nodeUrl: $nodeUrl,
            walletUrl: $walletUrl,
            helperUrl: $helperUrl,
            explorerUrl: $explorerUrl,
            accountId: $accountId
        ) {
            icon
            name
            symbol
            tokenId
            dailyAmount
            remainingAmount
            price
        }
    }
`;

const infoBoxStyle = {
    flexFlow: 'column wrap',
    placeContent: 'center',
    display: 'flex',
    alignItems: 'center',
    flex: '1',
    paddingBottom: '15px'
 };

 const dollarUSLocale = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    useGrouping: true,
  });

const theadData = ["LIQUIDATOR", "AMOUNT", "AVERAGE DISCOUNT"];
const theadData2 = ["DATE", "USER", "PROFIT AMOUNT", "TRANSACTION"];
const theadData3 = ["LOAN HOLDER", "LOAN RISK", "LOAN SIZE"];

const tokenMapping = {
    'wrap.near': { name: 'near', decimals: 24, displayName: "Wrapped NEAR" },
    'meta-pool.near': { name: 'staked-near', decimals: 24, displayName: "Staked NEAR" },
    'linear-protocol.near': { name: 'linear-protocol', decimals: 24, displayName: "" },
    'usn': { name: 'usn', decimals: 18, displayName: "" },
    'aurora': { name: 'ethereum', decimals: 18, displayName: "" },
    'token.skyward.near': { name: 'skyward-finance', decimals: 18, displayName: "" },
    'dbio.near': { name: 'debio-network', decimals: 18, displayName: "" },
    'meta-token.near': { name: 'meta-near', decimals: 24, displayName: "" },
    'v3.oin_finance.near': { name: 'oin-finance', decimals: 8, displayName: "" },
    'token.v2.ref-finance.near': { name: 'ref-finance', decimals: 18, displayName: "" },
    '6b175474e89094c44da98b954eedeac495271d0f.factory.bridge.near': { name: 'dai', decimals: 18, displayName: "Dai" },
    'a0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.factory.bridge.near': { name: 'usd-coin', decimals: 6, displayName: "USD Coin" },
    'dac17f958d2ee523a2206206994597c13d831ec7.factory.bridge.near': { name: 'tether', decimals: 6, displayName: "Tether USD" },
    '2260fac5e5542a773aa44fbcfedf7c193bc2c599.factory.bridge.near': { name: 'wrapped-bitcoin', decimals: 8, displayName: "" },
    'aaaaaa20d9e0e2461697782ef11675f668207961.factory.bridge.near': { name: 'aurora-near', decimals: 18, displayName: "" },
    '4691937a7508860f876c9c0a2a617e7d9e945d4b.factory.bridge.near': { name: 'woo-network', decimals: 18, displayName: "Wootrade Network" },
    'token.burrow.near': { name: 'burrow', decimals: 18, displayName: "BRRR" },
    'token.paras.near': { name: 'paras', decimals: 18, displayName: "" },
    'v2-nearx.stader-labs.near': { name: 'stader-nearx', decimals: 24, displayName: "" },
  }

type OwnProps = {
    theme: string;
};
  
type Props = OwnProps;

const AnalyticsPage: FC<Props> = ({theme}) => {
    const [histLiquidationsTableSelected, setHistLiquidationsTableSelected] = useState<string>('historical liquidations');
    const [topLiquidatingTableSelected, setTopLiquidatingTableSelected] = useState<string>('top liquidating accounts');
    const [highestRiskLoansTableSelected, setHighestRiskLoansTableSelected] = useState<string>('highest risk loans & sizes');
    const [averageDiscount, setAverageDiscount] = useState<string>('0%');
    const [averageLiquidationSize, setAverageLiquidationSize] = useState<string>('$0');
    const [histLiquidationsTable, setHistLiquidationsTable] = useState<HistLiquidationsTable>({'historical liquidations': []});
    const [topLiquidatingTable, setTopLiquidatingTable] = useState<TopLiquidatingTable>({'top liquidating accounts': []});
    const [highestRiskLoansTable, setHighestRiskLoansTable] = useState<HighestRiskLoansTable>({'highest risk loans & sizes': []});
    const [protocolDepositedValue, setProtocolDepositedValue] = useState<string>('$0');
    const [protocolBorrowedValue, setProtocolBorrowedValue] = useState<string>('$0');
    const [protocolNetLiquidityValue, setProtocolNetLiquidityValue] = useState<string>('$0');
    const location = useLocation();

    useEffect(()=>{
        window.localStorage.setItem('currPage',  location.pathname);
    },[location]);

    useEffect(() => {
        getHighestRiskLoans(setHighestRiskLoansTable);
    }, []);

    useEffect(() => {
        getTopLiquidatingAccounts(setTopLiquidatingTable);
    }, []);

    useEffect(() => {
        getHistoricalLiquidations(setHistLiquidationsTable);
    }, []);

    useEffect(() => {
        getProtocolAssetInfo(setProtocolBorrowedValue, setProtocolDepositedValue);
    }, []);

    useEffect(() => {
        getProtocolNetLiquidity(setProtocolNetLiquidityValue);
    }, []);
    
    useEffect(() => {
        getAverageDiscount(setAverageDiscount);
    }, []);

    const getAverageDiscount = async (setAverageDiscount) => {
        const averageLiquidationData = await makeGraphQLRequest(ENDPOINT, AVERAGE_LIQUIDATION_DATA_QUERY, VARIABLES);

        setAverageDiscount(`${averageLiquidationData.data.getLiquidationData.averageDiscount.toLocaleString(undefined, APY_FORMAT)}%`);
        setAverageLiquidationSize(`$${m(averageLiquidationData.data.getLiquidationData.averageLiquidationSize)}`);
    }

    const getHighestRiskLoans = async (setHighestRiskLoansTable) => {
        const highestRiskLoans = await makeGraphQLRequest(ENDPOINT, HIGH_RISK_LOANS_QUERY, VARIABLES);

        const highestRiskLoansList = generateHighestRiskLoansList(highestRiskLoans.data.getHighestRiskLoans);
        setHighestRiskLoansTable({
            'highest risk loans & sizes': highestRiskLoansList
        });
    }

    const getProtocolAssetInfo = async (setProtocolBorrowedValue, setProtocolDepositedValue) => {
        const protocolAssetInfo = await makeGraphQLRequest(ENDPOINT, PROTOCOL_ASSET_INFO_QUERY, VARIABLES);

        setProtocolBorrowedValue(`$${m(protocolAssetInfo.data.getProtocolAssetInfo.protocolBorrowed)}`);
        setProtocolDepositedValue(`$${m(protocolAssetInfo.data.getProtocolAssetInfo.protocolDeposited)}`);
    }

    const getProtocolNetLiquidity = async (setProtocolNetLiquidityValue) => {
        const protocolAssetInfo = await makeGraphQLRequest(ENDPOINT, PROTOCOL_NET_LIQUIDITY_QUERY, VARIABLES);

        const amount = protocolAssetInfo.data.getProtocolNetLiquidity.reduce(sumRewards, 0);        
        setProtocolNetLiquidityValue(amount.toLocaleString(undefined, USD_FORMAT));
    }

    const getTopLiquidatingAccounts = async (setTopLiquidatingTable) => {
        const topLiquidatingAccounts = await makeGraphQLRequest(ENDPOINT, TOP_LIQUIDATING_ACCOUNTS_QUERY);

        const topLiquidatingAccountsList = generateTopLiquidatingList(topLiquidatingAccounts.data.getTopLiquidatingAccounts);
        setTopLiquidatingTable({
            'top liquidating accounts': topLiquidatingAccountsList
        });
    }

    const getHistoricalLiquidations = async (setHistLiquidationsTable) => {
        const historicalLiquidations = await makeGraphQLRequest(ENDPOINT, HISTORICAL_LIQUIDATIONS_QUERY);

        const historicalLiquidationsList = generateHistoricalLiquidationsList(historicalLiquidations.data.getHistoricalLiquidations, tokenMapping);
        setHistLiquidationsTable({
            'historical liquidations': historicalLiquidationsList
        });
    }
    
    const processHistLiquidationEvent = (event, tokenMapping) => {
        const options = { timeZoneName: 'short' };
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timestamp = event.eventTimestamp;
        const date = new Date(timestamp.toUpperCase());

        // Get day, month, and year
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const dayOfWeek = daysOfWeek[date.getUTCDay()];
        const day = date.getUTCDate().toString().padStart(2, '0');
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = monthNames[date.getUTCMonth()];
        const year = date.toLocaleString('en', { year: 'numeric' });

        // Get hours, minutes, and seconds
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        // Get time zone abbreviation
        const timeZoneAbbr = Intl.DateTimeFormat(undefined, { timeZoneName: 'short' }).formatToParts(date)
        .find(part => part.type === 'timeZoneName').value;


        const formattedEvent = [
            {
                sortValue: new Date(parseFloat(event.eventTimestampp) / 1e6),
                value: `${dayOfWeek} ${day} ${month.toUpperCase()} ${year}\n${hours}:${minutes}:${seconds} ${timeZoneAbbr}`,
            },
            {
                sortValue: event.liquidationAccountId ? event.liquidationAccountId : "",
                value: `${event.liquidationAccountId ? event.liquidationAccountId.length > 16 ? event.liquidationAccountId.substring(0, Math.min(8, event.liquidationAccountId.length/2)).toUpperCase() + '...' + event.liquidationAccountId.toUpperCase().substring(event.liquidationAccountId.length - 8).toUpperCase() : event.liquidationAccountId.toUpperCase() : ""}\nLiquidated by ${event.actingAccountId.length > 16 ? event.actingAccountId.substring(0, Math.min(8, event.actingAccountId.length/2)).toUpperCase() + '...' + event.actingAccountId.toUpperCase().substring(event.actingAccountId.length - 8).toUpperCase() : event.actingAccountId.toUpperCase()}`,
            },
            event.eventType == 1 ? 
            {
                sortValue: event.collateralSum - event.repaidSum,
                value: `${dollarUSLocale.format(event.collateralSum - event.repaidSum)}`,
            } : 
            {
                sortValue: event.amount,
                value: `${Number(shrinkToken(event.amount, tokenMapping[event.predecessorId] ? tokenMapping[event.predecessorId].decimals : 18, 2))}\n${tokenMapping[event.predecessorId] ? tokenMapping[event.predecessorId].displayName.toUpperCase() : ""}`,
            },
            {
                sortValue: event.receiptId,
                value: `${event.receiptId.length > 16 ? event.receiptId.substring(0, Math.min(8, event.receiptId.length/2)).toUpperCase() + '...' + event.receiptId.toUpperCase().substring(event.receiptId.length - 8).toUpperCase() : event.receiptId}`, 
                link: `https://explorer.near.org/?query=${event.receiptId}`,
            },
        ];
    
        return formattedEvent;
    };
    
    const processTopLiquidatingEvent = (event) => {
        const formattedEvent = [
            {
                sortValue: event.accountId,
                value: `${event.accountId.length > 24 ? event.accountId.substring(0, Math.min(12, event.accountId.length/2)).toUpperCase() + '...' + event.accountId.toUpperCase().substring(event.accountId.length - 12).toUpperCase() : event.accountId.toUpperCase()}`, 
            },
            {
                sortValue: event.totalLiquidationAmount,
                value: `${dollarUSLocale.format(event.totalLiquidationAmount)}`,
            },
            {
                sortValue: event.averageDiscount,
                value: `${event.averageDiscount.toFixed(2)}%`,
            }
        ];

        return formattedEvent;
    };

    const processHighestRiskLoans = (account) => {
        const formattedEvent = [
            {
                sortValue: account.accountId,
                value: `${account.accountId.toUpperCase()}`, 
            },
            {
                sortValue: account.healthFactor,
                value: `${(account.healthFactor * 100).toFixed(2)}%`, 
            },
            {
                sortValue: account.adjustedDebt,
                value: `${dollarUSLocale.format(account.adjustedDebt)}`,
            }
        ];
    
        return formattedEvent;
    };

    const generateHistoricalLiquidationsList = (events, tokenMapping) => {
        const histLiqList = [];
    
        events.forEach((event) => {            
            const formattedEvent = processHistLiquidationEvent(event, tokenMapping);
            histLiqList.push(formattedEvent);
        });
    
        return histLiqList;
    };

    const generateHighestRiskLoansList = (accounts) => {
        const highestRiskLoans = [];
        
        accounts.forEach((account) => {
            const formattedEvent = processHighestRiskLoans(account);
            highestRiskLoans.push(formattedEvent);
        });
    
        return highestRiskLoans;
    };

    const generateTopLiquidatingList = (events) => {
        const topLiqList = [];
        
        events.forEach((event) => {
            const formattedEvent = processTopLiquidatingEvent(event);
            topLiqList.push(formattedEvent);
        });
    
        return topLiqList;
    };

    const chartProps = {
        symbol: 'Borrowed',
        interval: '1W',
        libraryPath: '/charting_library/',
        chartsStorageUrl: 'https://saveload.tradingview.com',
        chartsStorageApiVersion: '1.1',
        clientId: 'tradingview.com',
        userId: 'public_user_id',
        fullscreen: false,
        autosize: true,
        studiesOverrides: {},
        timeframe: '12M',
        hideSideToolbar: true,
        showLineGraph: true,
        isAnalyticsPage: true,
      }
  
    return (
        <>
            <div>
                <div style={{
                    display: 'flex',
                    height: '100%',
                    alignSelf: 'center',
                    alignItems: 'center',
                    placeContent: 'center',
                    padding: '125px 10% 3% 10%',
                    flexFlow: 'row wrap',
                    alignContent: 'center',
                    justifyContent: 'center',
                    flex: '4 1 0%',
                }}>
                    <div style={infoBoxStyle}><InfoBox width={215} divisor={2} title={'DEPOSITED'} value={protocolDepositedValue}/></div>
                    <div style={infoBoxStyle}><InfoBox width={215} divisor={2} title={'BORROWED'} value={protocolBorrowedValue}/></div>
                    <div style={infoBoxStyle}><InfoBox width={215} divisor={2} title={'NET LIQUIDITY DAILY REWARDS'} value={protocolNetLiquidityValue}/></div>
                    <div style={infoBoxStyle}><InfoBox width={215} divisor={2} title={'AVERAGE LIQUIDATION SIZE'} value={averageLiquidationSize}/></div>
                    <div style={infoBoxStyle}><InfoBox width={215} divisor={2} title={'AVERAGE DISCOUNT'} value={averageDiscount}/></div>
                </div>
                <div style={{
                    flex: '1',
                    alignSelf: 'center',
                    padding: '0px 8% 3%'
                }}>
                    <div style={{
                        flex: '1',
                        alignSelf: 'center',
                    }}>
                        <TradingChart datafeedUrl={''} container={undefined} theme={theme} {...chartProps}/>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            gap: '1.5%'
                        }}>
                        <div
                            style={{
                            flex: 1,
                            paddingTop: '20px',
                            minWidth: '250px'
                        }}>
                            <div>
                                <TableView
                                theadData={theadData}
                                tableOptions={topLiquidatingTable}
                                selected={topLiquidatingTableSelected}
                                numRows={6}
                            />
                            </div>
                            <div style={{
                                paddingTop: '20px',
                                minWidth: '250px'
                            }}>
                                <TableView
                                    theadData={theadData3}
                                    tableOptions={highestRiskLoansTable}
                                    selected={highestRiskLoansTableSelected}
                                    numRows={6}
                                />
                            </div>
                        </div>                    
                        <div style={{
                                paddingTop: '20px',
                                flex: '1',
                                minWidth: '250px'

                        }}>
                            <TableView
                                theadData={theadData2}
                                tableOptions={histLiquidationsTable}
                                selected={histLiquidationsTableSelected}
                                numRows={11}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AnalyticsPage;

import Decimal from "decimal.js";
import { LanguageCode } from "./charting_library";
import { TOKEN_FORMAT } from "./common/constants/constants";
import { AccountState } from "./common/interfaces/account";
import { Asset, Assets, AssetsState, UIAsset } from "./common/interfaces/asset";
import { AppState } from "./common/types/AppState";

// export const MAX_RATIO = 10000;

const sumReducerDecimal = (sum: Decimal, cur: Decimal) => sum.add(cur);
export const sumRewards = (acc, r) => acc + r.dailyAmount * r.price;

export const makeApiRequest = async (path) => {
	try {
		const response = await fetch(path);
		return response.json();
	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
}

export const makeGraphQLRequest = async (path, query, variables = {}) => {  
  try {
    if (query !== null) {
      const response = await fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': '0720738332de4955a55a2ef21d5a7127' // Subscription key for generated product
        },
        body: JSON.stringify({
          query: query,
          variables: variables,
        }),
      });

      return response.json();
    }
  } catch(error) {
    console.log('Fetch error: ', error);
  }
}

export const shrinkToken = (
  value: string | number,
  decimals: string | number,
  fixed?: number,
): string => {
  return new Decimal(value).div(new Decimal(10).pow(decimals)).toFixed(fixed);
};

export const addAssetBalances = (assets, balance, contract, type) : Object[] => {
  assets.push({
    contract: contract.contract,
    contractName: contract.contractName,
    balance: balance ? Number(new Decimal(balance).div(new Decimal(10).pow(contract.decimals))) : 0,
    type: type,
    coingeckoId: contract.coingeckoId,
  })
  
  return assets;
}

export const addBurrowAssets = (assets, contractData, type) : Object => {
  var assetsObj = {};
  assets.forEach(async asset => {
    var tokenId = asset.token_id;
    assetsObj[tokenId] = {
      contract: contractData[tokenId].contract,
      contractName: contractData[tokenId].contractName,
      balance: asset.balance ? Number(new Decimal(asset.balance).div(new Decimal(10).pow(contractData[tokenId].decimals))) : 0,
      apr: asset.apr,
      shares: asset.shares,
      coingeckoId: contractData[tokenId].coingeckoId,
      type: type
    };
  })
  
  return assetsObj;
}

export const getLanguageFromURL = (): LanguageCode | null => {  
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
};
import React, { FC } from 'react';
import styled from 'styled-components';

interface Prop {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  showBalance: boolean;
  accountBalance: string;
}

const UserInfoContainer = styled.div`
  height: 244px;
  width: 271px;
  border-radius: 25px;
  cursor: pointer;
  position: relative;

  background-color: ${props => props.theme.userInfoColor};

  @media (min-width: 768px) {
    height: 366px;
    width: 407px;
  }
`;

const UserInfoData = styled.span`
  font-family: Cantarell, sans-serif;
  font-weight: 700;
  letter-spacing: 2%;
  font-size: 30px;

  color:${props => props.theme.userInfoDataTextColor};

  @media (min-width: 768px) {
    font-size: 37px;
  }
`;

const UserInfoDataContainer = styled.div`
  width: 100%;
  text-align: center;
  height: 50%;
  top: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;

  filter: ${props => props.viewState ? 'blur(0px)' : 'blur(15px)'};
`;

const UserInfoTextContainer = styled.div`
  width: 100%;
  text-align: center;
  height: 50%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const UserInfoText = styled.span`
  font-family: Cantarell, sans-serif;
  font-weight: 700;
  letter-spacing: 2%;
  font-size: 18px;

  color:${props => props.theme.userInfoTextColor};

  @media (min-width: 768px) {
    font-size: 21px;
  }
`;



const UserInfo: FC<Prop> = ({ showBalance, accountBalance, onClick }) => {  
  
  return (
    <>
      <UserInfoContainer
        onClick={onClick}>
        <UserInfoDataContainer viewState={showBalance}>
          <UserInfoData>
            {accountBalance} USD
          </UserInfoData>
        </UserInfoDataContainer>
        <UserInfoTextContainer>
          <UserInfoText>
            Available Balance
          </UserInfoText>
        </UserInfoTextContainer>
      </UserInfoContainer>
    </>
  );
};

export default UserInfo;

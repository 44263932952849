import 'regenerator-runtime/runtime';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AnalyticsPage from './pages/AnalyticsPage/AnalyticsPage';
import HomePage from './pages/HomePage/HomePage';
import { GlobalStyles, lightTheme, darkTheme } from './themes';
import NavBar from './common/components/NavBar';
import { useDarkMode } from './common/styles/useDarkMode';

export default function App({ isSignedIn, wallet }) {
  const [theme, toggleTheme] = useDarkMode();
  
  return (
    <ThemeProvider theme={theme  === 'Light' ? lightTheme : darkTheme}>
      <GlobalStyles />
      <NavBar
        isSignedIn={isSignedIn}
        onClickConnect={() => wallet.signIn()}
        onClickDisconnect={() => wallet.signOut()}
        accountId={wallet.accountId}
        toggleTheme={toggleTheme}
        theme={theme}
      />
      <Routes>
        <Route path="/" element={
          <HomePage theme={theme} accountId={wallet.accountId} />
        }/>
        <Route path="/analytics" element={<AnalyticsPage theme={theme} />}/>
      </Routes>
    </ThemeProvider>
  );
}
